import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { css, cx } from "@emotion/css";

import api from "../../service/api";
import theme from "../../theme";
import { Fade } from "react-reveal";

const Home = () => {
  return (
    <>
      <Box>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            background: "#1b1464",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "100%",
              transform: "translate(-70%, -40%)",
              visibility: { xs: "hidden", sm: "visible" },
              width: { xs: "0px", sm: "80vw" },
              "& img": {
                width: "100%",
              },
            }}
          >
            <Fade right>
              <img src="home.png" alt="PCI edukasi" />
            </Fade>
          </Box>
          <Container
            maxWidth="lg"
            sx={{
              minHeight: { xs: "100vh" },
              color: "#ffffff",
              zIndex: 2,
              padding: { xs: "0px 10px", sm: "0px 30px" },
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: { xs: "50%" },
                transform: { xs: "translate(0,-50%)" },
                display: "flex",
                justifyContent: "left",
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
            >
              <Box
                sx={{
                  maxWidth: "400px",
                  flex: { xs: "1 1 100%", sm: "0 0 0px" },
                  visibility: { xs: "visible", sm: "hidden" },
                  marginLeft: { xs: "auto", sm: "0" },
                  marginRight: { xs: "auto", sm: "0" },
                }}
              >
                <Fade>
                  <img src="home.png" alt="PCI edukasi" width="100%" />
                </Fade>
              </Box>
              <Fade left>
                <Box
                  sx={{
                    flex: "0 1 512px",
                    textAlign: { xs: "center", sm: "left" },
                    maxWidth: { sm: "50%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { md: "36px", xs: "24px" },
                      fontWeight: "900",
                    }}
                  >
                    Sekolah dengan E-Perpustakaan, bukan lagi impian
                  </Typography>
                  <Typography sx={{ fontSize: { md: "32px", xs: "20px" } }}>
                    Telah hadir dengan layanan Pustaka PCI
                  </Typography>
                  <a
                    href="https://wa.me/6281345485100"
                    className={css`
                      text-decoration: none;
                    `}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        background: "#ffffff",
                        marginTop: "8px",
                        transform: "translateY(0)",
                        transition: "1s",
                        "&:hover": {
                          background: `${theme.palette.secondary.main} !important`,
                          transform: "translateY(-2px)",
                          color: "#ffffff",
                        },
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1em",
                      }}
                    >
                      <i className="fab fa-2x fa-whatsapp"></i>
                      <span>Daftar Sekarang</span>
                    </Button>
                  </a>
                </Box>
              </Fade>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            background: "#ffffff",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              minHeight: "100vh",
              color: "#000000",
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                paddingTop: "8em",
                transform: "translate(0,-50%)",
                display: "flex",
                justifyContent: "center",
                flexWrap: { xs: "wrap", sm: "nowrap" },
                alignItems: "flex-end",
                textAlign: "center",
              }}
            >
              <Box sx={{ flex: "100px 0 300px", padding: "8px" }}>
                <Fade bottom>
                  <Box>
                    <img
                      alt="Daring"
                      src="landing_undraw_bibliophile_re_xarc.svg"
                      className={css`
                        width: 100%;
                        max-height: 300px;
                      `}
                    />
                  </Box>
                  <Box sx={{ height: "200px" }}>
                    <Typography variant="h4">Daring</Typography>
                    <Typography variant="p">
                      Perpustakaan PCI adalah perpustakaan online. Akses mudah
                      dari mana saja.
                    </Typography>
                  </Box>
                </Fade>
              </Box>
              <Box sx={{ flex: "100px 0 300px", padding: "8px" }}>
                <Fade bottom delay={200}>
                  <Box>
                    <img
                      alt="Berkualitas"
                      src="landing_undraw_online_articles_re_yrkj.svg"
                      className={css`
                        width: 100%;
                        max-height: 300px;
                      `}
                    />
                  </Box>

                  <Box sx={{ height: "200px" }}>
                    <Typography variant="h4">Berkualitas</Typography>
                    <Typography variant="p">
                      Sarana perpustakaan dengan buku yang berkualitas. Telah di
                      review oleh pihak resmi.
                    </Typography>
                  </Box>
                </Fade>
              </Box>
              <Box sx={{ flex: "100px 0 300px", padding: "8px" }}>
                <Fade bottom delay={400}>
                  <Box>
                    <img
                      alt="Mudah"
                      src="landing_undraw_online_re_x00h.svg"
                      className={css`
                        width: 100%;
                        max-height: 300px;
                      `}
                    />
                  </Box>
                  <Box sx={{ height: "200px" }}>
                    <Typography variant="h4">Mudah</Typography>
                    <Typography variant="p">
                      Sangat mudah dan intuitif untuk menggunakan Pustaka PCI.
                      Sama seperti ebook reader.
                    </Typography>
                  </Box>
                </Fade>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            background: "#fafafa",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              maxWidth: "400px",
            }}
          >
            <img className="logo" alt="PCI" src="logo.png" width="100%" />
          </Box>
          <Box
            sx={{
              minWidth: { sm: "400px" },
              marginLeft: { xs: "0", md: "auto" },
            }}
          >
            <Box
              className="icons"
              sx={{
                "& :not(:first-child)": {
                  marginLeft: "4px",
                },
              }}
            >
              <a
                href="https://www.instagram.com/pci_edukasi/?hl=en"
                className={css`
                  color: black;
                `}
              >
                <i className="fab fa-2x fa-instagram"></i>
              </a>

              <a
                href="https://linktr.ee/pci_edukasi"
                className={css`
                  color: black;
                `}
              >
                <i className="fab fa-2x fa-whatsapp"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UC0WnKLY-QHeD6YDNG0BeLHQ"
                className={css`
                  color: black;
                `}
              >
                <i className="fab fa-2x fa-youtube"></i>
              </a>
              {/* <i className="far fa-2x fa-envelope"></i> */}
            </Box>
            <div className="address">
              <p>
                PT. Payung Cerdas Indonesia
                <br />
                Bukit Nanggerang Village, Blok F No. 2
                <br />
                RT.02/RW.04, Nanggerang, Tajurhalang, Bogor 16320
                <br />
                Senin - Jumat 08.00 - 17.00 WIB
                <br />
                0813 4548 5100
              </p>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
