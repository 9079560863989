import {
  Route,
  Routes,
  NavLink,
  Outlet,
  matchPath,
  useLocation,
} from "react-router-dom";

import {
  Tabs,
  Tab,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import theme from "../../theme";

function App() {
  const location = useLocation();
  return (
    <Box>
      <Box
        sx={{
          width: "240px",
          bgcolor: `${theme.palette.background.paper}`,
          position: "fixed",
          height: "100%",
        }}
      >
        <nav>
          <List
            sx={{
              position: "sticky",
              "& a": {
                color: "black",
              },
            }}
          >
            <ListItem to={"books"} component={NavLink} end>
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Koleksi Buku" />
            </ListItem>
            <ListItem to={"books/upload"} component={NavLink} end>
              <ListItemIcon>
                <UploadFileIcon />
              </ListItemIcon>
              <ListItemText primary="Upload Buku" />
            </ListItem>
            <ListItem to={"schools"} component={NavLink} end>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Daftar Sekolah" />
            </ListItem>
            <ListItem to={"schools/register"} component={NavLink} end>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Registrasi Sekolah" />
            </ListItem>
            <ListItem to={"validation"} component={NavLink} end>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Pembayaran" />
            </ListItem>
          </List>
        </nav>
      </Box>
      <Box sx={{ marginLeft: "240px" }}>
        <Box sx={{ padding: "0.2em" }}>
          <Box
            sx={{
              margin: "auto",
              width: "calc(100% - 2em)",
              borderBottom: "solid 1px #dddddd",
            }}
          >
            <Typography>Dasbor Admin</Typography>
          </Box>
        </Box>

        <Container maxWidth="lg">
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

export default App;
