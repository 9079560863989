import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Snackbar,
  Table,
  TextField,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { css, cx } from "@emotion/css";

import api from "../../service/api";
import config from "../../config";

const BookUpload = () => {
  const navigate = useNavigate();
  const [bookData, setBookData] = useState([]);
  const [updateId, setUpdateId] = useState(0);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  const [filter, setFilter] = useState({
    param: "",
    query: "",
  });

  const [alert, setAlert] = useState({ type: "", text: "", isOpen: false });

  const getBookData = async () => {
    let returnStatus = "";
    let returnResponse = "";
    await api
      .get(`admin/books`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setBookData((d) => res.data);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const deleteBook = (i) => {
    api
      .delete(`admin/books/${i._id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setUpdateId((s) => s + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmDelete = (i) => {
    setDeletePopup(true);
    setDeleteItem(i);
  };

  useEffect(() => {
    getBookData();
  }, [updateId]);

  const handleChange = (prop) => (e) => {
    setBookData({ ...bookData, [prop]: e.target.value });
  };

  const keyMapping = [
    { t: "ISBN", v: "ISBN" },
    { t: "Judul", v: "title" },
    { t: "Penulis/Penerbit", v: "author" },
    { t: "Publisher", v: "publisher" },
    { t: "Tahun terbit", v: "publishYear" },
    { t: "Jenjang", v: "schoolLevel" },
    { t: "Deskripsi", v: "description" },
    { t: "Harga (/Semester)", v: "pricePerSemester" },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "12px",
          p: "12px",
          m: 0,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          List Buku
        </Typography>

        <Box
          sx={{
            display: "flex",
            height: "2em",
            alignItems: "center",
            gap: "1em",
          }}
        >
          Filter:
          <FormControl fullWidth>
            <InputLabel required>Kolom</InputLabel>
            <Select
              label="Kolom"
              variant="outlined"
              value={filter.param}
              onChange={(e) =>
                setFilter((f) => ({ ...f, param: e.target.value }))
              }
              sx={{ flex: "0 0 100%" }}
            >
              {keyMapping.map((x) => (
                <MenuItem value={x.v}>{x.t}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            value={filter.query}
            label="Query"
            onChange={(e) =>
              setFilter((f) => ({ ...f, query: e.target.value }))
            }
          ></TextField>
        </Box>

        <TableContainer container="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Gambar</TableCell>
                {keyMapping.map((k) => (
                  <TableCell>{k.t}</TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {bookData
              .filter((b) =>
                filter.param
                  ? JSON.stringify(b[filter.param]).search(filter.query) >= 0
                  : true
              )
              .map((i) => (
                <TableRow>
                  <TableCell
                    className={css`
                      width: 100px;
                    `}
                  >
                    <img
                      src={`${config.baseUrl}/admin/books/${i._id}/thumb`}
                      alt={`${i.title}`}
                      className={css`
                        height: 100px;
                      `}
                    />
                  </TableCell>

                  {keyMapping.map((k) => (
                    <TableCell>{i[k.v]}</TableCell>
                  ))}
                  <TableCell>
                    <span
                      className={css`
                        display: flex;
                        gap: 4px;
                      `}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          navigate(`/admin/books/${i._id}`);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={(e) => confirmDelete(i)}
                      >
                        Delete
                      </Button>
                    </span>
                  </TableCell>
                  {/* <TableCell><p>{JSON.stringify(i)}</p></TableCell> */}
                </TableRow>
              ))}
          </Table>
        </TableContainer>
      </Box>
      <Snackbar
        open={alert?.isOpen}
        autoHideDuration={1000}
        onClose={() => setAlert((a) => ({ ...a, isOpen: false }))}
        message={alert.text}
      ></Snackbar>
      <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
        <DialogTitle>Konfirmasi Delete Buku</DialogTitle>
        <DialogContent>
          Apakah Anda yakin ingin menghapus buku {deleteItem?.title}, ISBN{" "}
          {deleteItem?.ISBN}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePopup(false)}>BATAL</Button>
          <Button
            onClick={() => {
              deleteBook(deleteItem);
              setDeletePopup(false);
            }}
            autoFocus
          >
            HAPUS
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BookUpload;
