import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: [
      '"Oxygen"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#1B1464",
    },
    secondary: {
      main: "#D09D32",
    },
    background: {
      paper: "#fafafa",
      dark: "#f9f9f9",
    },
  },
});

export default theme;
