import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { css, cx } from "@emotion/css";

import config from "../config";
import api from "../service/api";

const toURLPage = (pageNum, page) => {
  if (pageNum == null || page == null) {
    return "000";
  }

  const len = pageNum.toString().length;
  const pageStr = page.toString();
  const pageLen = pageStr.length;

  const zeros = "0".repeat(len - pageLen);
  return zeros + pageStr;
};

const BookReader = (props) => {
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState();
  const [imageURL, setImageURL] = useState("");
  const [imageData, setImageData] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!props.initialPage) {
      return;
    }
    setPage(props.initialPage);
  }, [props.initialPage]);

  useEffect(() => {
    console.log(imageURL);
  }, [imageURL]);

  useEffect(() => {
    if (!props.bookid) {
      return;
    }
    getBookInfo(props.bookid);
  }, [props.bookid]);

  useEffect(() => {
    setImageURL(
      `${config.baseUrl}/${props.userType}/books/${
        props.bookid
      }/html/${toURLPage(numPages, page)}`
    );
  }, [page, numPages, props.bookid]);

  useEffect(() => {
    getBookPage(props.bookid);
  }, [imageURL]);

  const getBookInfo = async (bookid) => {
    await api
      .get(`${props.userType}/books/${bookid}/info`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setNumPages((d) => res.data.numPages);
      })
      .catch((err) => {
        console.log(err);
        setError("Gagal memuat buku");
      });
  };

  const getBookPage = async (bookid) => {
    await api
      .get(`${imageURL}`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const dataURI = Buffer.from(res.data, "binary").toString("base64");
        setImageData(dataURI);
        setError(false);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setError("Gagal memuat buku");
      });
  };

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{ flex: "0 0 100%" }}
      >
        Book Reader
      </Typography>
      {console.log(page, numPages, props.bookid, imageURL)}

      {error || (
        <div
          alt="None"
          className={css`
            background-image: url(\"data:image/png;base64, ${imageData}\");
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 0;
            padding-top: 166.64%; /* (img-height / img-width * container-width) */
            /* (853 / 1280 * 100) */
          `}
        ></div>
      )}

      <Button onClick={() => setPage((p) => (numPages > p ? p + 1 : numPages))}>
        Next
      </Button>
      <Button onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}>Prev</Button>
    </>
  );
};

export default BookReader;
