import { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Link,
  Outlet,
  matchPath,
  useLocation,
} from "react-router-dom";

import { Tabs, Tab, Typography, Container } from "@mui/material";

import api from "../../service/api";

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function App() {
  const routeMatch = useRouteMatch([
    "student/mybooks",
    "student/profile",
    "student/read",
  ]);
  const currentTab = routeMatch?.pattern?.path;

  const [profile, setProfile] = useState();

  const getProfile = async () => {
    await api
      .get(`student/profile`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setProfile((d) => res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h2">{profile?.email}</Typography>
      <Tabs value={currentTab}>
        <Tab
          label="Buku Saya"
          value="student/mybooks"
          to="mybooks"
          component={Link}
        />
        <Tab label="Baca" value="student/read" to="read" component={Link} />

        <Tab
          label="Profil"
          value="student/profile"
          to="profile"
          component={Link}
        />
      </Tabs>

      <Outlet />
    </Container>
  );
}

export default App;
