import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import api from "../../service/api";

function App() {
  const [email, setEmail] = useState();
  const [error, setError] = useState();

  const navigate = useNavigate();
  const params = useParams();

  const sendConfirmation = async () => {
    var returnStatus, returnResponse;
    await api
      .post(
        `school/confirmation`,
        {
          email: params.email,
          token: params.confirmationToken,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          localStorage.setItem("token", res.data.token);
          // navigate("/school/login", { replace: true });
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  useEffect(() => {
    sendConfirmation();
    setEmail(params.email);
  }, []);

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Konfirmasi Email
        </Typography>
      </Box>
      <Box>
        <Typography variant="p" sx={{ marginBottom: "8px" }}>
          Email {email} telah terkonfirmasi.
          <Link href="/school/login">Klik disini untuk login.</Link>
        </Typography>
      </Box>
      <Box>
        <Typography variant="p" sx={{ marginBottom: "8px" }}>
          {error}
        </Typography>
      </Box>
    </Container>
  );
}

export default App;
