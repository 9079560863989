import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import api from "../../service/api";

function RegistrationPage() {
  const [npsn, setNpsn] = useState();
  const [email, setEmail] = useState();
  const [nama, setNama] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [error, setError] = useState();

  const navigate = useNavigate();

  const sendRegister = async () => {
    if (!npsn || !email || !nama || !password) {
      setError("Ada form yang kosong!");
      return;
    }

    if (password !== passwordConfirmation) {
      setError("Password tidak sama");
      return;
    }

    var returnStatus, returnResponse;
    await api
      .post(
        `school/register`,
        {
          npsn: npsn,
          email: email,
          nama: nama,
          password: password,
          publicUrl: window.location.host,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          navigate("/school/login", { replace: true });
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };
  return (
    <Container maxWidth="lg" sx={{ textAlign: "center", width: "50%" }}>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Registrasi Sekolah
        </Typography>
        <Typography variant="p">
          Silahkan kontak Admin PCI (via whatsaapp/email) untuk Registrasi
        </Typography>
      </Box>
    </Container>
  );
}

export default RegistrationPage;
