import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

import api from "../../service/api";

function App() {
  const [passwordRepeat, setPasswordRepeat] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const navigate = useNavigate();

  const params = useParams();

  const sendChangePassword = async () => {
    var returnStatus, returnResponse;

    console.log(password, passwordRepeat);
    if (
      password != passwordRepeat ||
      password === undefined ||
      password === ""
    ) {
      alert("Password tidak sama");
      setPasswordRepeat("");
      setPassword("");
      return;
    }
    await api
      .post(
        `school/changepassword`,
        {
          email: params.email,
          token: params.confirmationToken,
          password: password,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          navigate("/school/login", { replace: true });
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Isi password baru
        </Typography>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField
            label="Ulangi Password"
            variant="outlined"
            type="password"
            onChange={(e) => {
              setPasswordRepeat(e.target.value);
            }}
          />
        </Box>
        <Box>
          <Typography variant="error" sx={{ marginBottom: "8px" }}>
            {error}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{ marginBottom: "8px" }}
          onClick={() => sendChangePassword()}
        >
          Ganti Password
        </Button>
      </Box>
    </Container>
  );
}

export default App;
