import {
  Route,
  Routes,
  Outlet,
  matchPath,
  useLocation,
} from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";

function App() {
  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Login
        </Typography>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField label="Email" variant="outlined" />
        </Box>
        <Box sx={{ marginBottom: "8px" }}>
          <TextField label="Password" variant="outlined" />
        </Box>
        <Button variant="contained" sx={{ marginBottom: "8px" }}>
          Login
        </Button>
        <Box>
          <Typography variant="p">
            Lupa password? <Link href="#">Kirim email konfirmasi.</Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant="p">
            Belum terdaftar? <Link href="#">Register.</Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default App;
