import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";

function ErrorPage() {
  return (
    <Container maxWidth="lg" sx={{ textAlign: "center" }}>
      <Box>
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          Maaf, halaman tidak ditemukan
        </Typography>
        <img src="/404.png" alt="Not found" />
      </Box>
    </Container>
  );
}

export default ErrorPage;
