import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { css, cx } from "@emotion/css";

import BookReader from "../../components/BookReader.jsx";
import api from "../../service/api";

const BookUpload = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ width: "100vw" }}>
        {localStorage.getItem("bookid") ? (
          <BookReader
            bookid={localStorage.getItem("bookid")}
            initialPage={1}
            userType="student"
          />
        ) : (
          "Tidak ada buku terpilih"
        )}
      </Box>
    </>
  );
};

export default BookUpload;
