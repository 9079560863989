import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Fade,
  Modal,
  Backdrop,
  TextField,
} from "@mui/material";

// import { DatePicker, LocalizationProvider } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { css, cx } from "@emotion/css";

import api from "../../service/api";

const StudentPage = () => {
  const navigate = useNavigate();
  const [studentData, setstudentData] = useState([]);
  const [updateId, setUpdateId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [createModalData, setCreateModalData] = useState({
    email: "",
    name: "",
    nisn: "",
    birthDate: "",
    gender: "",
  });

  const [alert, setAlert] = useState({ type: "", text: "", isOpen: false });

  const sendNewStudent = async () => {
    let returnStatus = "";
    let returnResponse = "";
    console.log(createModalData);
    await api
      .post(
        `school/students`,
        { ...createModalData },
        {
          headers: {
            "x-access-token": `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";
        if (res.status === 200) {
          setModalOpen(false);
          setUpdateId((s) => s + 1);
        }
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const getstudentData = async () => {
    let returnStatus = "";
    let returnResponse = "";
    await api
      .get(`school/students`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setstudentData((d) => res.data);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  useEffect(() => {
    getstudentData();
  }, [updateId]);

  const handleChange = (prop) => (e) => {
    setstudentData({ ...studentData, [prop]: e.target.value });
  };

  const handleCreateModalChange = (prop) => (e) => {
    setCreateModalData({ ...createModalData, [prop]: e.target.value });
  };

  return (
    <>
      <Typography variant="h4" gutterBottom component="div">
        Data Murid
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "12px",
          p: "12px",
          m: 0,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          Daftar Murid
        </Typography>
        <Button variant="outlined" onClick={() => setModalOpen(true)}>
          Tambah Data Murid
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography variant="h6" component="h2">
                Tambah data murid
              </Typography>

              <Box sx={{ marginBottom: "8px" }}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email Murid"
                  variant="outlined"
                  value={createModalData?.email}
                  onChange={handleCreateModalChange("email")}
                />
              </Box>
              <Box sx={{ marginBottom: "8px" }}>
                <TextField
                  fullWidth
                  label="Nama Murid"
                  variant="outlined"
                  value={createModalData?.name}
                  onChange={handleCreateModalChange("name")}
                />
              </Box>

              <Box sx={{ marginBottom: "8px" }}>
                <TextField
                  fullWidth
                  label="NISN"
                  variant="outlined"
                  value={createModalData?.nisn}
                  onChange={handleCreateModalChange("nisn")}
                />
              </Box>

              {/* <Box sx={{ marginBottom: "8px" }}>
                <TextField
                  fullWidth
                  label="Tanggal Lahir"
                  variant="outlined"
                  type="datetime"
                />
              </Box> */}

              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Basic example"
                  value={createModalData?.birthDate}
                  onChange={handleCreateModalChange("birthDate")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}

              {/* <Box sx={{ marginBottom: "8px" }}>
                <TextField
                  fullWidth
                  label="Jenis Kelamin"
                  variant="outlined"
                  value={createModalData?.gender}
                  onChange={handleCreateModalChange("gender")}
                />
              </Box> */}
              <Button onClick={() => sendNewStudent()} variant="contained">
                Submit
              </Button>
              <Button onClick={() => setModalOpen(false)} variant="text">
                Batal
              </Button>
            </Box>
          </Fade>
        </Modal>

        <TableContainer container="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell
                  className={css`
                    width: max-content;
                  `}
                >
                  Nama
                </TableCell>
                <TableCell>NISN</TableCell>
                <TableCell>Tanggal Lahir</TableCell>
                <TableCell>Status Akun</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {studentData?.map((i, k) => (
              <TableRow key={k}>
                <TableCell
                  className={css`
                    width: max-content;
                    white-space: nowrap;
                  `}
                >
                  {i.email}
                </TableCell>
                <TableCell
                  className={css`
                    width: max-content;
                    white-space: nowrap;
                  `}
                >
                  {i.name}
                </TableCell>
                <TableCell
                  className={css`
                    width: max-content;
                    white-space: nowrap;
                  `}
                >
                  {i.nisn}
                </TableCell>
                <TableCell
                  className={css`
                    width: max-content;
                    white-space: nowrap;
                  `}
                >
                  {i.birthDate}
                </TableCell>
                <TableCell
                  className={css`
                    width: max-content;
                    white-space: nowrap;
                  `}
                >
                  {i.isActive ? "Aktif" : "Inaktif"}
                </TableCell>
                <TableCell>
                  <span
                    className={css`
                      display: flex;
                      gap: 4px;
                    `}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        api
                          .delete(`school/students/${i._id}`, {
                            headers: {
                              "Content-Type": "application/json",
                              "x-access-token": `${localStorage.getItem(
                                "token"
                              )}`,
                            },
                          })
                          .then((res) => {
                            console.log(res.data);
                            setUpdateId((s) => s + 1);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    >
                      Delete
                    </Button>
                  </span>
                </TableCell>
                {/* <TableCell><p>{JSON.stringify(i)}</p></TableCell> */}
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Box>
      <Snackbar
        open={alert?.isOpen}
        autoHideDuration={1000}
        onClose={() => setAlert((a) => ({ ...a, isOpen: false }))}
        message={alert.text}
      ></Snackbar>
    </>
  );
};

export default StudentPage;
