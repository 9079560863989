import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Tabs,
  Tab,
  Box,
  Typography,
  Container,
  TextField,
  Button,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import AdapterLuxon from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import api from "../../service/api";

function ProfilePage() {
  const [profile, setProfile] = useState({
    nisn: "",
    email: "",
    birthDate: null,
    gender: "",
  });
  const [error, setError] = useState();

  const navigate = useNavigate();

  const sendProfile = async () => {
    var returnStatus, returnResponse;
    await api
      .put(`school/profile`, profile, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          // Success
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const getProfile = async () => {
    var returnStatus, returnResponse;
    await api
      .get(`student/profile`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);

        if (res.status == 200) {
          setProfile(res.data);
        }

        returnStatus = "success";
        returnResponse = "Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Gagal";
        setError(err.toString());
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  const handleChange = (prop) => (e) => {
    setProfile({ ...profile, [prop]: e.target.value });
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ textAlign: "left" }}>
      <Box sx={{ width: { xs: "100%", lg: "50%" } }}>
        <Box>
          <Typography variant="h3" sx={{ marginBottom: "8px" }}>
            Profil Murid
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="NISN"
              variant="outlined"
              value={profile?.nisn}
              disabled
            />
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={profile?.email}
              disabled
            />
          </Box>
          <Typography variant="h4" sx={{ marginBottom: "8px" }}>
            Detil Murid
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            <FormControl fullWidth required>
              <InputLabel>Jenis Kelamin</InputLabel>
              <Select
                value={profile?.gender}
                label="Jenis Kelamin"
                onChange={handleChange("gender")}
              >
                <MenuItem value={"Laki-laki"}>Laki-laki</MenuItem>
                <MenuItem value={"Perempuan"}>Perempuan</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ marginBottom: "8px" }}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="Tanggal Lahir"
                value={profile?.birthDate}
                onChange={handleChange("birthDate")}
                renderInput={(params) => (
                  <TextField fullWidth {...params} required />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box>
            <Typography variant="error" sx={{ marginBottom: "8px" }}>
              {error}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{ marginBottom: "8px" }}
            onClick={() => sendProfile()}
          >
            Save
          </Button>
          <Box>
            <Typography variant="p">
              Lupa password? <Link href="#">Kirim email konfirmasi.</Link>
            </Typography>
          </Box>
          <Box>
            <Typography variant="p">
              Belum terdaftar? <Link href="#">Register.</Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ProfilePage;
