import axios from "axios";
import config from "../config";

const Api = axios.create({
  baseURL: config.baseUrl,
  timeout: config.timeout,
  headers: {},
});

export default Api;
