import { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Link,
  Outlet,
  matchPath,
  useLocation,
} from "react-router-dom";

import { Tabs, Tab, Typography, Container } from "@mui/material";

import api from "../../service/api";

function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function App() {
  const routeMatch = useRouteMatch([
    "school/books",
    "school/students",
    "school/buybooks",
  ]);
  const currentTab = routeMatch?.pattern?.path;
  const [profile, setProfile] = useState();

  const getProfile = async () => {
    await api
      .get(`school/profile`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setProfile((d) => res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h2">Halo {profile?.email}!</Typography>
      <Tabs value={currentTab}>
        <Tab
          label="Katalog Buku"
          value="school/books"
          to="books"
          component={Link}
        />
        {/* <Tab
          label="Buku Saya"
          value="school/mybooks"
          to="mybooks"
          component={Link}
        /> */}
        {/* <Tab label="Baca" value="school/read" to="read" component={Link} /> */}
        <Tab
          label="Data Murid"
          value="school/students"
          to="students"
          component={Link}
        />
        <Tab
          label="Beli Buku"
          value="school/buybooks"
          to="buybooks"
          component={Link}
        />
      </Tabs>

      <Outlet />
    </Container>
  );
}

export default App;
