import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { css, cx } from "@emotion/css";

import api from "../../service/api";
import config from "../../config";

const BookUpload = () => {
  const navigate = useNavigate();
  const [bookData, setBookData] = useState([]);
  const [updateId, setUpdateId] = useState(0);

  const [alert, setAlert] = useState({ type: "", text: "", isOpen: false });

  const getBookData = async () => {
    let returnStatus = "";
    let returnResponse = "";
    await api
      .get(`school/books`, {
        headers: {
          "x-access-token": `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
      .then((res) => {
        console.log(res.data);
        setBookData((d) => res.data);
        returnStatus = "success";
        returnResponse = "Book Upload Berhasil";
      })
      .catch((err) => {
        console.log(err);
        returnStatus = "error";
        returnResponse = "Upload Gagal";
      });

    return {
      status: returnStatus,
      text: returnResponse,
    };
  };

  useEffect(() => {
    getBookData();
  }, [updateId]);

  const handleChange = (prop) => (e) => {
    setBookData({ ...bookData, [prop]: e.target.value });
  };

  return (
    <>
      <Typography variant="h4" gutterBottom component="div">
        Data Buku
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          gap: "12px",
          p: "12px",
          m: 0,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ flex: "0 0 100%" }}
        >
          Katalog Buku
        </Typography>

        <TableContainer container="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Gambar</TableCell>
                <TableCell
                  className={css`
                    width: max-content;
                  `}
                >
                  ISBN
                </TableCell>
                <TableCell>Judul</TableCell>
                <TableCell>Penulis/Penerbit</TableCell>
                <TableCell>Info lain</TableCell>
                <TableCell>Harga Per Semester</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {bookData.map((i) => (
              <TableRow>
                <TableCell
                  className={css`
                    width: 100px;
                  `}
                >
                  <img
                    src={`${config.baseUrl}/school/books/${i._id}/thumb`}
                    alt={`${i.title}`}
                    className={css`
                      height: 100px;
                    `}
                  />
                </TableCell>
                <TableCell
                  className={css`
                    width: max-content;
                    white-space: nowrap;
                  `}
                >
                  {i.ISBN}
                </TableCell>
                <TableCell
                  className={css`
                    width: max-content;
                    white-space: nowrap;
                  `}
                >
                  {i.title}
                </TableCell>
                <TableCell>
                  {i.author}
                  <br />
                  {i.publisher}
                  <br />
                  {i.publishYear}
                </TableCell>
                <TableCell>
                  {i.schoolLevel}
                  <br />
                  {i.description}
                </TableCell>
                <TableCell>{i.pricePerSemester}</TableCell>
                <TableCell>
                  <span
                    className={css`
                      display: flex;
                      gap: 4px;
                    `}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<ShoppingCartIcon />}
                      onClick={() => {
                        api
                          .post(
                            `school/buybooks`,
                            {
                              bookId: i._id,
                              duration: "1y",
                            },
                            {
                              headers: {
                                "Content-Type": "application/json",
                                "x-access-token": `${localStorage.getItem(
                                  "token"
                                )}`,
                              },
                            }
                          )
                          .then((res) => {
                            console.log(res.data);
                            setUpdateId((s) => s + 1);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    >
                      Langganan
                    </Button>
                  </span>
                </TableCell>
                {/* <TableCell><p>{JSON.stringify(i)}</p></TableCell> */}
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Box>
      <Snackbar
        open={alert?.isOpen}
        autoHideDuration={1000}
        onClose={() => setAlert((a) => ({ ...a, isOpen: false }))}
        message={alert.text}
      ></Snackbar>
    </>
  );
};

export default BookUpload;
